<template>
  <LoadingState v-if="isLoadingPage" />
  <div v-else>
    <div v-if="!menus.length">No Students Found</div>
    <div v-else>
      <a-modal
        title=""
        :visible="isVisibleModalSendConfirmation"
        :confirm-loading="isConfirmLoadingModalSendConfirmation"
        @ok="handleOkModalSendConfirmation"
        @cancel="handleToggleModalSendConfirmation"
        destroyOnClose
        :closable="false"
        :maskClosable="false"
      >
        <h3>
          Are you sure want to send Periodic Report to all Student Parent(s)?
        </h3>
        <p class="mt-2">
          Please fill the Reporting Date and Periodic Report Type
        </p>
        <hr />
        <a-form-item label="Reporting Date">
          <a-range-picker
            :disabledDate="disabledDate"
            :value="formSendConfirmation.selectedDates"
            format="DD-MM-YYYY"
            class="w-100"
            size="large"
            @change="onChangeConfirmationSelectedDate"
          />
        </a-form-item>
        <a-form-item label="Report Type">
          <a-radio-group
            class="w-100"
            size="large"
            :defaultValue="formSendConfirmation.reportType"
            v-model="formSendConfirmation.reportType"
            button-style="solid"
          >
            <a-radio-button class="w-50 text-center" value="status">
              Status Report
            </a-radio-button>
            <a-radio-button class="w-50 text-center" value="score">
              Score Report
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <small>
          Note: <br />
          This action will take faster or longer, depending on your internet
          condition. Please don't close the page if it's already in progress of
          sending the email.
        </small>
        <template slot="footer">
          <a-button
            :disabled="isConfirmLoadingModalSendConfirmation"
            size="large"
            key="back"
            @click="handleToggleModalSendConfirmation"
          >
            Cancel
          </a-button>
          <a-button
            :disabled="formSendConfirmation.selectedDates ? formSendConfirmation.selectedDates.includes('' || null) : false"
            size="large"
            key="submit"
            type="primary"
            :loading="isConfirmLoadingModalSendConfirmation"
            @click="handleOkModalSendConfirmation"
          >
            {{ isConfirmLoadingModalSendConfirmation ? "Sending" : "Send Now" }}
          </a-button>
        </template>
      </a-modal>
      <a-row :gutter="[16, 16]">
        <a-col :xs="24" :lg="8">
          <a-button
            @click="checkIsCompletedEmail"
            class="w-100"
            size="large"
            type="primary"
            >Send Periodic Report</a-button
          >
        </a-col>
        <a-col :xs="24" :lg="16">
          <a-range-picker :disabledDate="disabledDate" class="w-100" size="large" @change="onChangeDates" :value="selectedDates" format="DD-MM-YYYY" />
        </a-col>
      </a-row>
      <div class="d-flex align-items-center"></div>
      <div class="mt-4">
        <a-row :gutter="[16, 16]">
          <a-col
            :sm="24"
            :xl="8"
            v-sticky="{
              zIndex: 99,
              stickyTop: 10,
              disabled: isDisabledSticky,
            }"
          >
            <div class="border rounded">
              <div class="card-header">
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    w-100
                  "
                >
                  <div>
                    <h2 class="font-size-18 text-dark">List of Students</h2>
                  </div>
                </div>
              </div>
              <div class="card-body scrolling-card">
                <Menu
                  :menus="menus"
                  :menuActive="menuActive"
                  @menu-change="menuChange"
                />
              </div>
              <div class="card-footer">
                <kbd class="bg-primary">↑</kbd>
                <kbd class="bg-primary mx-2">↓</kbd>
                <span>To Navigate</span>
              </div>
            </div>
          </a-col>
          <a-col :sm="24" :xl="16">
            <div class="border rounded">
              <div class="card-header">
                <div
                  class="
                    d-flex
                    flex-column flex-md-row
                    align-items-start align-items-md-center
                    w-100
                  "
                >
                  <div>
                    <h2 class="font-size-18 text-dark">Preview</h2>
                  </div>
                  <div class="ml-0 ml-md-auto">
                    <a-select
                      size="large"
                      v-model="reportType"
                      style="width: 160px"
                      @change="handleChangeReportType"
                    >
                      <a-select-option value="status"
                        >Status Report</a-select-option
                      >
                      <a-select-option value="score"
                        >Score Report</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
              </div>
              <div
                style="min-height: 562px"
                class="card-body d-flex flex-column align-items-center"
              >
                <template
                  v-if="selectedDates && selectedDates.length && !selectedDates.includes('' || null)"
                >
                  <LoadingState v-if="isLoadingPeriodicReportPreview" />
                  <div v-else class="w-100">
                    <report-periodic
                      v-if="assignmentAndDailyExam"
                      :reportType="reportType"
                      :reportData="assignmentAndDailyExam"
                      :itemLoops="itemLoops"
                    />
                    <div v-else>No Data Found</div>
                  </div>
                </template>
                <template v-else>
                  <date-picker-illustration
                    class="my-auto"
                    title="No Preview To Show"
                    content="Select the Reporting Date to show the preview"
                  />
                </template>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import VueSticky from 'vue-sticky'
import JsPdf from 'jspdf'
import html2canvas from 'html2canvas'
import 'jspdf-autotable'
import { minus, checkSuccess, checkDanger, gradeWarning } from '@/helpers/statusReport'
import moment from 'moment'
const LoadingState = () => import('@/components/app/LoadingState')
const Menu = () => import('@/components/app/MenuV2')
const ReportPeriodic = () => import('@/components/app/Report/Periodic')
const DatePickerIllustration = () => import('@/components/app/shared/Illustration/DatePicker')

const menus = [
  // {
  //   key: 1,
  //   id: 1,
  //   menu: 'Afrina Zufra',
  // },
  // {
  //   key: 2,
  //   id: 2,
  //   menu: ' Elvina Amalia Wardanu',
  // },
  // {
  //   key: 3,
  //   id: 3,
  //   menu: 'Achmad Fahrel Hanafi',
  // },
  // {
  //   key: 4,
  //   id: 4,
  //   menu: 'Adhyaksa Pri Kuncoro Jakti',
  // },
  // {
  //   key: 5,
  //   id: 5,
  //   menu: 'Adzka Farras Rahman',
  // },
]

const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'No',
    width: 50,
    align: 'center',
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
    align: 'left',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'left',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    align: 'center',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    width: 100,
    align: 'center',
  },
]
// const assignmentAndDailyExam = {
//   nis: '921312312',
//   name: 'Afrina Zufra',
//   class: 'VII-1',
//   homeroom_teacher: 'Lukman Sardi',
//   reporting_date: '1 June 2021 - 30 June 2021',
//   average_assignment_score: 85.2,
//   average_daily_exam_score: 90,
//   list_assignments: [
//     {
//       date: 'Friday, 4 June 2021',
//       lists: [
//         {
//           id: 1,
//           no: 1,
//           subject: 'Ilmu Pengetahuan Alam',
//           type: 'Essay',
//           score: 100,
//           status: 'above',
//         },
//         {
//           id: 2,
//           no: 2,
//           subject: 'Matematika',
//           type: 'File Upload',
//           score: 95,
//           status: 'bellow',
//         },
//       ],
//     },
//     {
//       date: 'Tuesday, 1 June 2021',
//       lists: [
//         {
//           id: 3,
//           no: 1,
//           subject: 'Bahasa Indonesia',
//           type: 'Multiple Choice',
//           score: 83,
//           status: null,
//         },
//         {
//           id: 5,
//           no: 3,
//           subject: 'Bahasa Arab',
//           type: 'Essay',
//           score: 96,
//           status: 'not_gradded',
//         },
//       ],
//     },
//   ],
//   list_daily_exams: [
//     {
//       date: 'Friday, 30 June 2021',
//       lists: [
//         {
//           id: 1,
//           no: 1,
//           subject: 'Ilmu Pengetahuan Alam',
//           type: 'Essay',
//           score: 92,
//           status: 'above',
//         },
//         {
//           id: 2,
//           no: 2,
//           subject: 'Matematika',
//           type: 'Multiple Choice & Essay',
//           score: 89,
//           status: null,
//         },
//       ],
//     },
//     {
//       date: 'Tuesday, 20 June 2021',
//       lists: [
//         {
//           id: 3,
//           no: 1,
//           subject: 'Bahasa Indonesia',
//           type: 'Multiple Choice',
//           score: 98,
//           status: 'above',
//         },
//       ],
//     },
//   ],
// }
const assignmentAndDailyExam = null
export default {
  props: {
    dataClass: {
      type: Object,
      required: true,
    },
  },
  directives: {
    sticky: VueSticky,
  },
  components: {
    LoadingState,
    Menu,
    ReportPeriodic,
    DatePickerIllustration,
  },
  data() {
    return {
      menus,
      columns,
      assignmentAndDailyExam,
      menuActive: 1,
      reportType: 'status',
      reportData: {},
      itemLoops: ['list_assignments', 'list_daily_exams'],
      selectedDates: [moment().add(-7, 'day'), moment()],
      formSendConfirmation: {
        selectedDates: null,
        reportType: 'status',
      },
      isLoadingPage: false,
      isLoadingPeriodicReportPreview: false,
      isVisibleModalSendConfirmation: false,
      isConfirmLoadingModalSendConfirmation: false,
      schoolYearActive: {},
    }
  },
  watch: {
    menuActive(val) {
      if (this.selectedDates && !this.selectedDates.includes('' || null)) {
        this.fetchDataReportPreview()
      }
    },
  },
  async mounted() {
    try {
      this.fetchTahunAjaranActive()
      this.fetchDataListMurid()
      window.addEventListener('keyup', (event) => {
        event.preventDefault()
        if (this.menus.length) {
          if (event.keyCode === 40 && this.menuActive !== this.menus.length) {
            this.menuActive = this.menuActive + 1
          } else if (event.keyCode === 38 && this.menuActive !== 1) {
            this.menuActive = this.menuActive - 1
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    disabledDate(current, old) {
      if (current) {
        // const condition = this.dataSchoolYear.tanggal_awal ? current < this.dataSchoolYear.tanggal_awal : false
        return current.isBefore(moment(this.schoolYearActive.tanggal_awal, 'YYYY-MM-DD')) || current.isAfter(moment(this.schoolYearActive.tanggal_akhir, 'YYYY-MM-DD'))
      }
      // return current && current < this.dataSchoolYear.tanggal_awal
    },
    async fetchTahunAjaranActive() {
      try {
        const data = await this.$store.dispatch('master/FETCH_TAHUN_AJARAN_ACTIVE')
        this.schoolYearActive = data || {}
      } catch (err) {
        console.log(err)
      }
    },
    checkIsCompletedEmail() {
      const totalStudents = this.menus.filter(menu => !menu.email_perwakilan_orangtua).length
      if (totalStudents) {
        this.$router.push({ name: 'Personal Data Homeroom Teacher' })
        this.$store.commit('menu/SET_STATE', {
          currentMenu: ['Personal Data Homeroom Teacher'],
        })

        this.$notification.warning({
          message: 'Warning',
          description: `There are still ${totalStudents} Student(s) who dont have their Parent Email, Please complete it first.`,
          duration: 8,
        })
      } else {
        this.handleToggleModalSendConfirmation()
      }
    },
    handleToggleModalSendConfirmation() {
      this.isVisibleModalSendConfirmation = !this.isVisibleModalSendConfirmation
      if (this.isVisibleModalSendConfirmation) {
        this.formSendConfirmation.selectedDates = this.selectedDates
      }
    },
    async handleOkModalSendConfirmation() {
      try {
        this.isConfirmLoadingModalSendConfirmation = true
        const { idKelas } = this.dataClass
        const formData = {
          ...this.formSendConfirmation,
          selectedDates: this.formSendConfirmation.selectedDates?.map(date => date.format('YYYY-MM-DD')),
        }
        if (!formData.selectedDates?.length || formData.selectedDates?.includes('' || null)) {
          return this.$notification.error({
            message: 'Vaildation Error',
            description: 'Please fill the reporting date',
          })
        }
        await this.$store.dispatch('homeroom/SEND_PERIODIC_REPORT', { idKelas, formData })
        this.handleToggleModalSendConfirmation()

        this.formSendConfirmation = {
          selectedDates: null,
          reportType: 'status',
        }
        return this.$notification.success({
          message: 'Success',
          description: 'The process of sending Periodic Reports has been successful',
        })
      } catch (error) {
        this.handleToggleModalSendConfirmation()
        this.formSendConfirmation = {
          selectedDates: null,
          reportType: 'status',
        }
        console.log(error)
        return this.$notification.error({
          message: 'Error',
          description: 'The process of sending Periodic Reports encountered problems. Please try again later',
        })
      } finally {
        this.isConfirmLoadingModalSendConfirmation = false
      }
    },
    async fetchDataListMurid() {
      try {
        this.isLoadingPage = true
        const { idKelas } = this.dataClass
        const { data } = await this.$store.dispatch('homeroom/FETCH_LIST_STUDENTS_CLASS', { idKelas })
        this.menus = data
        this.menuActive = this.menus[0]?.key
      } catch (error) {
        console.log(error)
        return this.$notification.error({
          message: 'Error',
          description: 'The process of fetching list students encountered problems. Please try again later',
        })
      } finally {
        this.fetchDataReportPreview()
        this.isLoadingPage = false
      }
    },
    async fetchDataReportPreview() {
      try {
        this.isLoadingPeriodicReportPreview = true
        const target = this.menus.find(menu => menu.key === this.menuActive)
        const paramsMurid = target?.id
        const selectedDays = this.selectedDates?.map(date => moment(date).format('YYYY-MM-DD')) || null
        const { data } = await this.$store.dispatch('homeroom/FETCH_STUDENT_PERIODIC_REPORT_PREVIEW', { paramsMurid, selectedDays })
        this.assignmentAndDailyExam = data
        // console.log('masuk')
      } catch (error) {
        console.log(error)
        return this.$notification.error({
          message: 'Error',
          description: 'The process of fetching student periodic report preview encountered problems. Please try again later',
        })
      } finally {
        this.isLoadingPeriodicReportPreview = false
      }
    },
    handleChangeReportType(value) {
      console.log(value)
    },
    sendReport() {
      const element = document.getElementById('report-periodic')
      html2canvas(element, { allowTaint: true }).then(canvas => {
        const htmlWidth = canvas.width
        const htmlHeight = canvas.height
        const topLeftMargin = 50
        const PdfWidth = htmlWidth + (topLeftMargin * 2)
        const PdfHeight = (PdfWidth * 1.5) + (topLeftMargin * 2)
        const canvasImageWidth = htmlWidth
        const canvasImageHeight = htmlHeight
        const totalPDFPages = Math.ceil(htmlHeight / PdfHeight) - 1
        canvas.getContext('2d')
        const imgData = canvas.toDataURL('image/jpeg', 1.0)
        // console.log(PdfHeight)
        const pdf = new JsPdf('p', 'px', [PdfWidth, PdfHeight])
        pdf.addImage(imgData, 'JPG', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight)
        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage([PdfWidth, PdfHeight], 'p')
          pdf.addImage(imgData, 'JPG', topLeftMargin, -(PdfHeight * i) + (topLeftMargin * 4), canvasImageWidth, canvasImageHeight)
        }
        pdf.save('test.pdf')
      })
    },
    sendReport2() {
      const head = [{ no: 'No.', subject: 'Subject', type: 'Type' }]
      if (this.reportType === 'status') {
        head[0].status = 'Status'
      } else if (this.reportType === 'score') {
        head[0].score = 'Score'
      }
      const doc = new JsPdf()
      const LEFT = 14
      let TOP = 10
      const pageSize = doc.internal.pageSize
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()

      doc.setFontSize(14)
      doc.setFont('times', 'bold')
      doc.text(`PERIODIC ${this.reportType === 'status' ? 'STATUS' : this.reportType === 'score' ? 'SCORE' : ''} REPORT`, LEFT, TOP)
      if (!this.assignmentAndDailyExam) {
        TOP += 5
        doc.setFontSize(10)
        doc.setFont('times', 'normal')
        doc.text('NO DATA FOUND', LEFT, TOP)
        doc.save('PERIODIC REPORT.pdf')
      } else {
        TOP += 5
        doc.setFontSize(10)
        doc.setFont('times', 'normal')
        doc.text(`${this.assignmentAndDailyExam.class} Class | ${this.assignmentAndDailyExam.homeroom_teacher}`, LEFT, TOP)

        TOP += 5
        doc.line(LEFT, TOP, pageWidth - LEFT, TOP)

        TOP += 11
        doc.setFontSize(12)
        doc.setFont('times', 'bold')
        doc.text('Student NIS', LEFT, TOP)
        doc.text('Student Name', LEFT + 50, TOP)
        doc.text('Reporting Date', LEFT + 120, TOP)

        TOP += 5
        doc.setFontSize(10)
        doc.setFont('times', 'normal')
        doc.text(this.assignmentAndDailyExam.nis, LEFT, TOP)
        const splitName = doc.splitTextToSize(this.assignmentAndDailyExam.name, 60)
        doc.text(splitName, LEFT + 50, TOP)
        doc.text(this.assignmentAndDailyExam.reporting_date, LEFT + 120, TOP)

        TOP += 9
        doc.line(LEFT, TOP, pageWidth - LEFT, TOP)

        if (this.reportType === 'status') {
          if (this.assignmentAndDailyExam.list_assignments.length || this.assignmentAndDailyExam.list_daily_exams.length) {
            TOP += 10
            doc.setFontSize(12)
            doc.setFont('times', 'bold')
            doc.text('Notes: ', LEFT, TOP)

            TOP += 5
            doc.setFontSize(10)
            doc.setFont('times', 'normal')
            doc.addImage(checkSuccess, 'PNG', LEFT, TOP, 5, 5)
            doc.text('Above the Passing Grade (KKM)', LEFT + 8, TOP + 3.5)

            TOP += 8
            doc.setFontSize(10)
            doc.setFont('times', 'normal')
            doc.addImage(checkDanger, 'PNG', LEFT, TOP, 5, 5)
            doc.text('Bellow the Passing Grade (KKM)', LEFT + 8, TOP + 3.5)

            TOP += 8
            doc.setFontSize(10)
            doc.setFont('times', 'normal')
            doc.addImage(gradeWarning, 'PNG', LEFT, TOP, 5, 5)
            doc.text('Not graded yet', LEFT + 8, TOP + 3.5)

            TOP += 8
            doc.setFontSize(10)
            doc.setFont('times', 'normal')
            doc.addImage(minus, 'PNG', LEFT, TOP, 5, 5)
            doc.text('Not submitted yet', LEFT + 8, TOP + 3.5)
          }
        }

        const imagesStatus = []
        TOP += 15
        let titleLoop = 'Assignments'
        for (let i = 0; i < this.itemLoops.length; i++) {
          const target = this.itemLoops[i]
          let avgScore = this.assignmentAndDailyExam.average_assignment_score || '-'
          if (target === 'list_daily_exams') {
            TOP = doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY : TOP + 20
            TOP += 10
            titleLoop = 'Daily Exams (UH)'
            avgScore = this.assignmentAndDailyExam.average_daily_exam_score || '-'
          }

          doc.setFontSize(12)
          doc.setFont('times', 'bold')
          doc.text(titleLoop, LEFT, TOP)
          if (this.reportType === 'score') doc.text(`Average Score: ${avgScore}`, pageWidth - LEFT, TOP, 'right', 'middle')

          TOP += 5
          doc.setFontSize(10)
          doc.setFont('times', 'normal')
          if (!this.assignmentAndDailyExam[target].length) doc.text(`No ${titleLoop} Data`, LEFT, TOP + 3)
          for (let j = 0; j < this.assignmentAndDailyExam[target].length; j++) {
            const item = this.assignmentAndDailyExam[target][j]
            const totalList = item.lists.length
            const subHead = `${item.date} | ${totalList} ${target === 'list_assignments' ? 'Assignment' : target === 'list_daily_exams' ? 'Daily Exam' : ''}(s)`

            if (j !== 0) {
              TOP = doc.lastAutoTable.finalY + 8
            } else {
              TOP += 2
            }

            doc.text(subHead, LEFT, TOP)
            doc.autoTable({
              head,
              body: item.lists,
              startY: TOP + 3,
              pageBreak: 'avoid',
              columnStyles: {
                no: { halign: 'right', cellWidth: 15 },
                subject: { cellWidth: 85 },
                type: { cellWidth: 60 },
                status: { halign: 'right', cellWidth: 22.8 },
              },
              styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.4,
              },
              headStyles: {
                // fillColor: [191, 191, 191],
                // textColor: [0, 0, 0],
              },
              didParseCell: function (data) {
                if (data.column.dataKey === 'no' || data.column.dataKey === 'status') data.cell.styles.halign = 'center'
                if (data.row.section === 'body') {
                  if (data.column.dataKey === 'status') {
                    data.cell.text = ''
                  }
                }
              },
              willDrawCell: function (data) {
                let image = minus
                if (data.column.dataKey === 'status') {
                  if (data.row.section === 'body') {
                    if (data.cell.raw === 'graded') {
                      image = checkSuccess
                    } else if (data.cell.raw === 'above') {
                      image = checkSuccess
                    } else if (data.cell.raw === 'equal') {
                      image = checkSuccess
                    } else if (data.cell.raw === 'below') {
                      image = checkDanger
                      doc.setTextColor(231, 76, 60)
                    } else if (data.cell.raw === 'not_graded') {
                      image = gradeWarning
                    }
                    imagesStatus.push({
                      image,
                      x: data.cell.x,
                      y: data.cell.y,
                    })
                  }
                }
              },
              didDrawCell: (data) => {
                if (data.column.dataKey === 'status') {
                  if (data.row.section === 'body') {
                    let img = minus
                    if (data.cell.raw === 'graded') {
                      img = checkSuccess
                    } else if (data.cell.raw === 'above') {
                      img = checkSuccess
                    } else if (data.cell.raw === 'equal') {
                      img = checkSuccess
                    } else if (data.cell.raw === 'below') {
                      img = checkDanger
                      doc.setTextColor(231, 76, 60)
                    } else if (data.cell.raw === 'not_graded') {
                      img = gradeWarning
                    }
                    doc.addImage(img, 'PNG', data.cell.x + 9, data.cell.y + 1, 5, 5)
                  }
                }
              },
            })
          }
        }
        doc.save(`Periodic ${this.reportType === 'status' ? 'Status' : this.reportType === 'score' ? 'Score' : ''} Report ${this.assignmentAndDailyExam.reporting_date}.pdf`)
      }
    },
    onChangeConfirmationSelectedDate(date, dateString) {
      this.formSendConfirmation.selectedDates = date
    },
    onChangeDates(date, dateString) {
      this.selectedDates = date.length ? date : null
      if (this.selectedDates && !this.selectedDates.includes('' || null)) {
        this.fetchDataReportPreview()
      }
    },
    menuChange(payload) {
      this.menuActive = payload
      if (this.selectedDates && !this.selectedDates.includes('' || null)) {
        this.fetchDataReportPreview()
      }
    },
  },
  computed: {
    menuName() {
      const menu = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
    isDisabledSticky() {
      if (!this.isDesktop) return true

      return false
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
}
</script>

<style lang="scss">
.scrolling-card {
  height: 500px;
  overflow-y: auto;
}
</style>
